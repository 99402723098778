import { useEffect } from "react"
import { ADMIN_USERS } from "../utils/admin_users"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"


export default function ExclusiveLayout({
  children,
}) {
    const history = useHistory();

    const validateUserAccess = () => {
        const userData = JSON.parse(localStorage.getItem('userData'))
        if (userData === null) {
            history.push({pathname:  "/exclusive/login"})
          }
        else if (!ADMIN_USERS.includes(userData.email)) {
            history.push({pathname:  "/exclusive/login"})
        }
    }

    useEffect(() => {
        validateUserAccess()
    },[])
    
    return (
        <div>
            { children }
        </div>
    )
}
