import { firestore } from "../../../firebase";

export const saveVoucher = async (payload, id = null) => {
  try {
    let docRef
    if(id){
      docRef = firestore.collection('vouchers').doc(id);
    }else {
      docRef = firestore.collection('vouchers').doc();
    }
    
    await docRef.set(payload);

  } catch (error) {
    console.error('Error saving data to Firestore:', error);
  }
}

export const retrieveVouchers = async () => {
    try {
      const querySnapshot = await firestore
        .collection('vouchers')
        .orderBy('createdAt', 'desc')
        .get();
  
      if (querySnapshot.empty) {
        return '0';
      }
  
      let data = []
      querySnapshot.docs.map((doc)=> {
        let temp = doc.data()
        temp.id = doc.id
        data.push(temp)
      })
      
      return data
    } catch (error) {
      console.error('Error retrieving data from Firestore:', error);
      return '0';
    }
  }

  export const fetchVoucherData = async (id) => {
    try {
      const querySnapshot = await firestore
        .collection('vouchers')
        .where('__name__', '==', id)
        .limit(1)
        .get();
  
      if (!querySnapshot.empty) {
        // Get the first (and only) document from the querySnapshot
        const docSnapshot = querySnapshot.docs[0];
        const data = docSnapshot.data();
        return data;
      } else {
        console.log('Document not found.');
      }
    } catch (error) {
      console.error('Error retrieving data from Firestore:', error);
    }
  };