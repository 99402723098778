import React, { Component } from 'react';
import Input from '../../../components/atoms/Input';
import exclusive_route from '../../../config/exclusive-route/exclusive_route.json'
import { Link } from 'react-router-dom';
import { ADMIN_USERS } from '../../../utils/admin_users';
import { Button } from 'react-bootstrap';
import ExclusiveLayout from '../../ExclusiveLayout';

class Exclusive extends Component {
  state = {
    search: ""
  }

  handleChangeText = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleOnClick = (link) => {
    this.props.history.push(link)
  }

  render() {
    // this.validateUserAccess()
    return(
      <ExclusiveLayout >
        <div className="min-h-screen bg-blue-50">
          <Input id="search" 
            type="search" 
            onChange={this.handleChangeText}
            placeholder="Search here..."/>
          <div className="flex m-10 justify-center flex-wrap">
            {
              exclusive_route.filter((val) => {
                if (this.state.search === ""){
                  return val
                } else if (val.name.toLowerCase().includes(this.state.search.toLowerCase())){
                  return val
                }
              }).map((val, key) => {
                return (
                  <div className="flex m-2 border bg-white w-60 justify-center shadow border-blue-600 cursor-pointer transform hover:-translate-y-1 transition duration-100 hover:shadow-2xl" key={key}>
                    <Link to={val.link}>
                      <h2 className="m-2 text-gray-800">{val.name}</h2>
                    </Link>
                  </div>
                )
              })
            }
            <Button title="tester"/>
          </div>
          
        </div>
      </ExclusiveLayout>
    )
  }
}

export default Exclusive;