import domtoimage from "dom-to-image";
import header from "../../../../../assets/img/logo/voucher-header.png"
import sideHeader from "../../../../../assets/img/logo/voucher-side-header.png"
import voucherTravel from "../../../../../assets/img/logo/voucher-travel.png"
import jsPDF from "jspdf"
import { useState } from "react";
import Input from "../../../../../components/atoms/Input";
import { toIDR } from "../../../../../utils/toIDR";
import Button from "../../../../../components/atoms/Button";
import ExclusiveLayout from "../../../../ExclusiveLayout";
import { saveVoucher } from "../../../../../config/redux/action/voucher";

const ExclusiveCreateVoucher = () => {
  const [loadingDownload, setLoadingDownload] = useState(false)
  const [domesticDiscount, setDomesticDiscount] = useState("0")
  const [intlDiscount, setIntlDiscount] = useState("")
  const [voucherCode, setVoucherCode] = useState("")
  const [customer, setCustomer] = useState("")
  const [expiryDate, setExpiryDate] = useState("30 Desember 2024")
   
  const handleDownloadPDF = async () => {
    const capture = document.querySelector("#invoice-container");
    const dataUrl = await domtoimage.toPng(capture)
    const doc = new jsPDF("landscape", "mm", [1488/5,650/5]);
    const img = new Image();
    img.src = dataUrl;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      const pdfWidth = 300;
      const pdfHeight = (img.height * pdfWidth) / img.width;
      doc.addImage(canvas, "PNG", 0, 0, pdfWidth, pdfHeight);
      doc.save(`${customer}.pdf`);
    };
  };

  const handleSaveVoucher = async () => {
    const createdAt = Number(new Date()); 
    const payload = {
        domesticDiscount,
        intlDiscount,
        customer,
        voucherCode,
        expiryDate,
        createdAt
    }

    await saveVoucher(payload)
  }

  return(
    <ExclusiveLayout>
      <div className="min-h-screen mb-20">
        <div className="md:flex">
          <div className={ loadingDownload ? "block md:block scale-100 ml-10 mt-10 font-sans" : "hidden md:block scale-100 ml-10 mt-10 font-sans"} >
            <div id="invoice-container" className="relative w-a4 h-a4 bg-white border-black">
              <div className="flex bg-voucher-gray">
                <div className="w-4/6 h-20 pt-3">
                    <div>
                        <img alt='header' src={header} className="w-full"/>
                    </div>
                    <div className="h-1 bg-jh-blue mx-12"></div>
                    <div className="mt-5 flex space-x-5 mx-10">
                        <div className="bg-jh-orange h-20 w-1/2 rounded-lg border-dashed border-2 border-jh-blue py-2">
                            <p className="text-center text-2xl text-gray-100">IDR {toIDR(domesticDiscount)}</p>
                            <p className="text-center text-lg text-gray-100">Domestic Tour</p>
                        </div>
                        <div className="bg-jh-orange h-20 w-1/2 rounded-lg border-dashed border-2 border-jh-blue py-2">
                            <p className="text-center text-2xl text-gray-100">IDR {toIDR(intlDiscount)}</p>
                            <p className="text-center text-lg text-gray-100">International Tour</p>
                        </div>
                    </div>
                    <div className="mt-5 mx-12">
                        <p className="font-semibold text-jh-orange">Syarat & Ketentuan :</p>
                        <p className="text-jh-orange">1. Pembelian paket tour berlaku untuk minimal 2 orang.</p>
                        <p className="text-jh-orange">2. Promo tidak berlaku kelipatan.</p>
                        <p className="text-jh-orange">3. Voucher tidak dapat digabungkan dengan voucher/promo lain.</p>
                        <p className="text-jh-orange">4. Voucher hanya dapat digunakan untuk 1 kali transaksi.</p>
                        <p className="text-jh-orange">5. Masa berlaku voucher hingga {expiryDate}.</p>
                    </div>
                </div>
                <div className="w-2/6 bg-jh-blue pt-5 pb-10">
                    <div>
                        <img alt='side-header' src={sideHeader} className="w-full"/>
                    </div>
                    <div className="mt-5 w-10/12 m-auto">
                        <img alt='side-header' src={voucherTravel} className="w-full"/>
                    </div>
                    <div className="px-5 mt-3">
                        <div className="bg-voucher-gray h-12 rounded-lg pt-3 px-5">
                            <p className="text-xl font-semibold text-center">{voucherCode}</p>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ml-10 mt-10 mr-10">
            <div className="border border-gray-300 rounded-md p-3 shadow-lg pb-5">
              <div className="flex space-x-2">
                <div className="w-1/2">
                  <Input label={'Domestic Discount'} placeholder={'150000'} onChange={(e) => setDomesticDiscount(e.target.value)}/>
                </div>
                <div className="w-1/2">
                  <Input label={'International Discount'} placeholder={'250000'} onChange={(e) => setIntlDiscount(e.target.value)}/>
                </div>
              </div>
              <div className="md:flex md:space-x-2">
                <div className="md:w-1/2">
                  <Input label={'Customer'} placeholder={'Customer Name'} onChange={(e) => setCustomer(e.target.value)}/>
                </div>
                <div className="md:w-1/2">
                  <Input label={'Voucher Code'} placeholder={'ABC123'} onChange={(e) => setVoucherCode(e.target.value)}/>
                </div>
              </div>
              <div className="md:flex md:space-x-2">
                <div className="md:w-full">
                  <Input label={'Expiry Date'} placeholder={'30 Desember 2024'} onChange={(e) => setExpiryDate(e.target.value)}/>
                </div>
              </div>
            </div>

            
            
            {
              loadingDownload ? (
                <div className="mt-10">
                  <div className="w-full h-0.5px bg-gray-900 mb-3"></div>
                  <p>Loading...</p>
                </div>
              ) : (
                <div>
                  <div className="mt-10">
                    <div className="w-full h-0.5px bg-gray-900 mb-3"></div>
                      <Button title='Save & Export PDF' onClick={async () => {
                        setLoadingDownload(true)
                        await handleSaveVoucher()
                        await handleDownloadPDF()
                        setLoadingDownload(false)
                        window.location.href="/exclusive/voucher/manage"
                        return false
                      }}/>
                  </div>

                  <div className="mt-5">
                    <div className="px-5 py-3 bg-yellow-600 hover:bg-yellow-500 inline text-white rounded-lg cursor-pointer" onClick={async () => {
                      setLoadingDownload(true)
                      await handleSaveVoucher()
                      setLoadingDownload(false)
                      window.location.href="/exclusive/voucher/manage"
                      return false
                    }}>
                      Save Only
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </ExclusiveLayout>
  )
}

export default ExclusiveCreateVoucher
