import domtoimage from "dom-to-image";
import header from "../../../../../assets/img/logo/invoice-header.png"
import footer from "../../../../../assets/img/logo/invoice-footer.png"
import jsPDF from "jspdf"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../../components/atoms/Input";
import { multiplyIDR, substractIDR, sumIDR, toIDR } from "../../../../../utils/toIDR";
import Button from "../../../../../components/atoms/Button";
import { getToursDataAPI } from "../../../../../config/redux/action/tour";
import { REFUND_OPTION } from "../../../../../utils/enums";
import { fetchInvoiceData, saveOfflineInvoice } from "../../../../../config/redux/action/offline_invoice";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CustomDatepicker from "../../../../../components/atoms/Datepicker";
import axios from "axios";
import ExclusiveLayout from "../../../../ExclusiveLayout";
import { storage } from "../../../../../config/firebase";

const ExclusiveEditInvoice = () => {
  const {id} = useParams()

  const [invoiceDate, setInvoiceDate] = useState('-')
  const [customer, setCustomer] = useState('-')
  const [tripName, setTripName] = useState('-')
  const [refundOptions, setRefundOptions] = useState([])
  const [tripDate, setTripDate] = useState('')
  const [tripPax, setTripPax] = useState('-')
  const [invoiceAmount, setInvoiceAmount] = useState('0')
  const [priceDetails, setPriceDetails] = useState([])
  const [priceDetailCounter, setPriceDetailCounter] = useState(0)
  const [recordPayments, setRecordPayments] = useState([])
  const [recordPaymentCounter, setRecordPaymentCounter] = useState(0)
  const [invoiceId, setInvoiceId] = useState('-')
  const [invoiceCounter, setInvoiceCounter] = useState('0')
  const [paymentStatus, setPaymentStatus] = useState('')
  const [createdAt, setCreatedAt] = useState()
  const [remainingAmount, setRemainingAmout] = useState()
  const [loadingDownload, setLoadingDownload] = useState(false)
  const [reminderPaymentDate, setReminderPaymentDate] = useState(new Date())
  const [reminderReceiverEmails, setReminderReceiverEmails] = useState("")
  const [showTaxInformation, setShowTaxInformation] = useState(false)
  
  const dispatch = useDispatch()
  const tourList = useSelector(state => state.tours)

  const getInvoiceById = async () => {
    const data = await fetchInvoiceData(id)
    setInvoiceDate(data.invoiceDate)
    setCustomer(data.customer)
    setInvoiceAmount(data.invoiceAmount)
    setInvoiceId(data.invoiceId)
    setPaymentStatus(data.paymentStatus)
    setPriceDetails(data.priceDetails)
    setPriceDetailCounter(data.priceDetails.length)
    setRecordPayments(data.recordPayments)
    setRecordPaymentCounter(data.recordPayments.length)
    setRefundOptions(data.refundOptions)
    setTripDate(data.tripDate)
    setTripName(data.tripName)
    setTripPax(data.tripPax)
    setCreatedAt(data.createdAt)
    setInvoiceCounter(data.invoiceCounter)
    setRemainingAmout(data.remainingAmount)
    data.reminderPaymentDate ? setReminderPaymentDate(data.reminderPaymentDate) : setReminderPaymentDate(new Date())
    data.reminderReceiverEmails ? setReminderReceiverEmails(data.reminderReceiverEmails) : setReminderReceiverEmails("")
    data.showTaxInformation ? setShowTaxInformation(data.showTaxInformation) : setShowTaxInformation(false)
  }

  const getTours = () => {
    dispatch(getToursDataAPI())
  }

  useEffect(() => {
    getTours()
    getInvoiceById()
  },[])

  const handleTripNameChange = (e) => {
    const selectedTitle = e.target.value;
    setTripName(selectedTitle);

    // Find the corresponding tourDetail object in tourList based on selectedTitle
    const selectedTourDetail = tourList.find(tourDetail => tourDetail.data.title === selectedTitle);

    // Update the refundOption state with the refundOption from the selectedTourDetail
    const refundOption = selectedTourDetail.data.refund_option
    if (refundOption === REFUND_OPTION.CUSTOM){
      setRefundOptions(selectedTourDetail.data.customRefundOptions)
    }else if (refundOption === REFUND_OPTION.MAX_7) {
      setRefundOptions([
        {
          downpayment: 'No refund',
          fullpayment: '-',
          timeframe: '> 7 hari'
        },
        {
          downpayment: 'No refund',
          fullpayment: '75% refund',
          timeframe: '6 - 7 hari'
        },
        {
          downpayment: 'No refund',
          fullpayment: 'No refund',
          timeframe: '0 - 6 hari'
        }
      ])
    }else if (refundOption === REFUND_OPTION.MAX_14){
      setRefundOptions([
        {
          downpayment: 'No refund',
          fullpayment: '-',
          timeframe: '> 14 hari'
        },
        {
          downpayment: 'No refund',
          fullpayment: '75% refund',
          timeframe: '8 - 14 hari'
        },
        {
          downpayment: 'No refund',
          fullpayment: 'No refund',
          timeframe: '0 - 7 hari'
        }
      ])
    }else {
      setRefundOptions([
        {
          downpayment: 'No refund',
          fullpayment: '-',
          timeframe: '> 30 hari'
        },
        {
          downpayment: 'No refund',
          fullpayment: '75% refund',
          timeframe: '21 - 30 hari'
        },
        {
          downpayment: 'No refund',
          fullpayment: 'No refund',
          timeframe: '0 - 21 hari'
        }
      ])
    }
  };
   
  const handleDownloadPDF = async () => {
    const capture = document.querySelector("#invoice-container");
    const dataUrl = await domtoimage.toPng(capture)
    const doc = new jsPDF("p", "mm", "a4");
    const img = new Image();
    img.src = dataUrl;
    img.onload = async () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      const pdfWidth = 210; // A4 width in mm
      const pdfHeight = (img.height * pdfWidth) / img.width;
      doc.addImage(canvas, "PNG", 0, 0, pdfWidth, pdfHeight);
      doc.save(`${invoiceId}.pdf`);
    };
  };

  const getPDFUrl = async () => {
    const capture = document.querySelector("#invoice-container");
    const dataUrl = await domtoimage.toPng(capture)
    const doc = new jsPDF("p", "mm", "a4");
    const img = new Image();
    img.src = dataUrl;

    return new Promise((resolve, reject) => {
      img.onload = async () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
  
        const pdfWidth = 210; // A4 width in mm
        const pdfHeight = (img.height * pdfWidth) / img.width;
        doc.addImage(canvas, "PNG", 0, 0, pdfWidth, pdfHeight);
        
        try{
          const url = await uploadInvoice(doc.output("blob"), invoiceId.replaceAll("/","-"))
          console.log("PDF uploaded successfully:", url);
          resolve(url)
        } catch {
          reject("Failed get PDF URL")
        }
        
      };
    })
  };

  const uploadInvoice = async (doc, invoiceId) => {
    return await new Promise((resolve, reject) => {
      const url = storage.ref(`invoices/${invoiceId}`).put(doc);
      url.on(
        "state_changed",
        snapshot => {},
        error => {
            reject(false)
        },
        () => {
            storage.ref('invoices').child(invoiceId).getDownloadURL().then(
            url => {
                resolve(url)
            }
            )
        }
      )
    })
  }

  const handleSaveInvoice = async () => {
    const payload = {
      invoiceCounter,
      invoiceId,
      customer,
      tripName,
      refundOptions,
      tripDate,
      tripPax,
      priceDetails,
      recordPayments,
      paymentStatus,
      invoiceAmount,
      createdAt,
      invoiceDate,
      remainingAmount,
      reminderPaymentDate,
      reminderReceiverEmails,
      showTaxInformation
    }

    if (invoiceCounter) {
      await saveOfflineInvoice(payload)
    } else {
      console.error('Error: invoiceCounter is empty or undefined.');
    }
  }

  const handleSetPaymentReminder = async () => {
    if( reminderReceiverEmails !== "" ){
      const attachementUrl = await getPDFUrl()
      const utcPlus7Date = new Date(reminderPaymentDate.getTime() + (7 * 60 * 60 * 1000))
      utcPlus7Date.setUTCHours(2)
      const triggerTime = Math.floor(utcPlus7Date.getTime() / 1000);
      const receiversEmail = reminderReceiverEmails.replaceAll(" ","").split(",")
  
      const payload = {
        subject: `Payment Reminder - Jelajah Holiday ${invoiceId}`,
        message: `Hi ${customer}, you have a pending payment to complete. This email is a reminder to complete the payment on invoice ${invoiceId}. Please find the details of the invoice from the attachment below.`,
        to: receiversEmail,
        bcc: ["jelajah.holiday@gmail.com","farhan.farasdak@gmail.com","raka.verdiyanto@gmail.com"],
        trigger_time: triggerTime,
        idempotency_id: invoiceId,
        attachments: [{
          name: invoiceId,
          type: 'pdf',
          url: attachementUrl
        }]
      }

      console.log(payload)

      await axios.post("https://api.aturee.com/schedule-mail", payload)
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  const handleCancelPaymentReminder = async () => {
    const payload = {
      event_type: "email",
      idempotency_id: invoiceId,
    }
    await axios.post("https://api.aturee.com/void-event", payload)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleRemovePriceDetail = (index) => {
    const tempPriceDetails = priceDetails
    tempPriceDetails.splice(index,1)
    setPriceDetails(tempPriceDetails)
    setPriceDetailCounter(priceDetailCounter - 1)
  }

  const handleRemovePaymentRecord = (index) => {
    const tempRecordPayments = recordPayments
    tempRecordPayments.splice(index,1)
    setRecordPayments(tempRecordPayments)
    setRecordPaymentCounter(recordPaymentCounter - 1)
  }

  const handlePriceChange = (index, key, value) => {
    setPriceDetails((prevData) => {
      const tempData = [...prevData];
      tempData[index] = tempData[index] ?? {};
      tempData[index][key] = value;
      return tempData;
    });
  }

  const handleRecordPaymentChange = (index, key, value) => {
    setRecordPayments((prevData) => {
      const tempData = [...prevData];
      tempData[index] = tempData[index] ?? {};
      tempData[index][key] = value;
      return tempData;
    });
  }

  useEffect(() => {
    let totalPrice = 0
    priceDetails.forEach(priceDetail => {
        const price = priceDetail['price'] ? priceDetail['price'] : ''
        const quantity = priceDetail['quantity'] ? priceDetail['quantity'] : ''
        const tempTotalPrice = multiplyIDR(price, quantity)
        totalPrice = sumIDR(totalPrice, tempTotalPrice)
    })
    setInvoiceAmount(totalPrice)
  },[priceDetails]) 

  useEffect(() => {
    let paidPayment = 0
    let canceledPayment
    recordPayments.forEach(recordPayment => {
      const price = recordPayment['price'] ? recordPayment['price'] : ''
      const status = recordPayment['status'] ? recordPayment['status'] : ''
      if(status === 'LUNAS'){
        paidPayment = sumIDR(paidPayment, price)
      }
      if(status === 'DIBATALKAN'){
        canceledPayment = true
      }
    })
    setRemainingAmout(substractIDR(invoiceAmount, paidPayment))
    if(canceledPayment){
      setPaymentStatus('DIBATALKAN')
    } else if(invoiceAmount === paidPayment){
      setPaymentStatus('LUNAS')
    }else if(paidPayment === 0){
      setPaymentStatus('MENUNGGU PEMBAYARAN')
    }else{
      setPaymentStatus('MENUNGGU PELUNASAN')
    }
  },[recordPayments, invoiceAmount, recordPaymentCounter, priceDetailCounter])

  return(
    <ExclusiveLayout>
      <div className="min-h-screen mb-20">
        <div className="md:flex">
          <div className={ loadingDownload ? "block md:block scale-100 ml-10 mt-10 mb-10 font-sans" : "hidden md:block scale-100 ml-10 mt-10 mb-10 font-sans"} >
            <div id="invoice-container" className="relative w-a4 h-a4 bg-white border border-black">
              <div className="w-3/5 m-auto mt-5">
                <img alt='header' src={header}/>
              </div>
              <div className="w-full absolute bottom-0">
                <img alt='footer' src={footer}/>
              </div>

              <div className="mx-20">
                <div className="text-right mt-10">
                  <p>Jakarta, {invoiceDate}</p>
                </div>

                <p className="mt-3">Dear <span className="font-semibold">{customer}</span></p>
                <p className="mt-3">Terima kasih telah mengajukan pembelian paket <span className="font-semibold">{tripName}</span> <span className="font-semibold">{tripDate}</span> untuk <span className="font-semibold">{tripPax}</span> orang.</p>
                <p className="mt-3">Silakan selesaikan transaksi Anda sesuai dengan informasi dibawah:</p>
                <div className="flex">
                  <p>Invoice ID : </p>
                  <p className="ml-5">{invoiceId}</p>
                </div>
                <div className="flex">
                  <p>Jumlah Transaksi : </p>
                  <p className="ml-5"><span className="font-semibold">Rp {invoiceAmount}</span></p>
                </div>
                <table className="table-auto">
                    <thead className="border-2 border-gray-800">
                      <tr>
                        <th className="text-sm md:text-lg border-2 border-blue-500 bg-blue-500 text-gray-100 text-center">No</th>
                        <th className="text-sm md:text-lg border-2 border-blue-500 bg-blue-500 text-gray-100 text-center">Deskripsi</th>
                        <th className="text-sm md:text-lg border-2 border-blue-500 bg-blue-500 text-gray-100 text-center">Harga</th>
                        <th className="text-sm md:text-lg border-2 border-blue-500 bg-blue-500 text-gray-100 text-center">Jumlah</th>
                        <th className="text-sm md:text-lg border-2 border-blue-500 bg-blue-500 text-gray-100 text-center">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {priceDetails.map((priceDetail, index) => (
                        <tr key={index}>
                          <td className="text-sm md:text-lg border-2 border-blue-500 text-center pl-5 pr-5">{index + 1}</td>
                          <td className="text-sm md:text-lg border-2 border-blue-500 text-left pl-2 pr-5">{priceDetail['description']}</td>
                          <td className="text-sm md:text-lg border-2 border-blue-500 text-right pl-5 pr-2">Rp {toIDR(priceDetail['price'])}</td>
                          <td className="text-sm md:text-lg border-2 border-blue-500 text-center pl-5 pr-5">{priceDetail['quantity']}</td>
                          <td className="text-sm md:text-lg border-2 border-blue-500 text-right pl-5 pr-2">Rp {multiplyIDR(priceDetail['price'], priceDetail['quantity']) || 0 }</td>
                        </tr>
                      ))}
                      {
                        priceDetails.length === 0 ? (
                          <tr>
                            <td className="text-sm md:text-lg border-2 border-blue-500 text-center pl-5 pr-5">-</td>
                            <td className="text-sm md:text-lg border-2 border-blue-500 text-center pl-5 pr-5">-</td>
                            <td className="text-sm md:text-lg border-2 border-blue-500 text-center pl-5 pr-5">-</td>
                            <td className="text-sm md:text-lg border-2 border-blue-500 text-center pl-5 pr-5">-</td>
                            <td className="text-sm md:text-lg border-2 border-blue-500 text-center pl-5 pr-5">-</td>
                          </tr>
                        ) : null
                      }
                      <tr>
                        <td className="text-sm md:text-lg border-2 border-blue-500 bg-blue-500 text-center pl-5 pr-5"></td>
                        <td className="text-sm md:text-lg border-2 border-blue-500 bg-blue-500 text-gray-100 text-center pl-5 pr-5 font-semibold">Total Pembayaran</td>
                        <td className="text-sm md:text-lg border-2 border-blue-500 bg-blue-500 text-center pl-5 pr-5"></td>
                        <td className="text-sm md:text-lg border-2 border-blue-500 bg-blue-500 text-center pl-5 pr-5"></td>
                        <td className="text-sm md:text-lg border-2 border-blue-500 bg-blue-500 text-gray-100 text-right pl-5 pr-2">Rp {invoiceAmount}</td>
                      </tr>
                    </tbody>
                </table>

                <p className="mt-3 font-semibold">Status Pembayaran : 
                {
                  paymentStatus === 'LUNAS' ? <span className="pl-2 text-green-600">LUNAS</span> : null
                } 
                {
                  paymentStatus === 'MENUNGGU PEMBAYARAN' ? <span className="pl-2 text-yellow-600">MENUNGGU PEMBAYARAN</span> : null
                }
                {
                  paymentStatus === 'MENUNGGU PELUNASAN' ? <span className="pl-2 text-yellow-600">MENUNGGU PELUNASAN Rp {remainingAmount}</span> : null
                }
                {
                  paymentStatus === 'DIBATALKAN' ? <span className="pl-2 text-red-600">DIBATALKAN</span> : null
                }
                </p>
                {
                  recordPayments.length === 0 ? null : (
                    <table className="table-auto">
                      <thead className="border-2 border-blue-500">
                        <tr>
                          <th className="text-sm md:text-lg border-2 border-blue-500 bg-blue-500 text-gray-100 text-center">Deskripsi</th>
                          <th className="text-sm md:text-lg border-2 border-blue-500 bg-blue-500 text-gray-100 text-center">Total</th>
                          <th className="text-sm md:text-lg border-2 border-blue-500 bg-blue-500 text-gray-100 text-center">Waktu</th>
                          <th className="text-sm md:text-lg border-2 border-blue-500 bg-blue-500 text-gray-100 text-center">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {recordPayments.map((recordPayment, index) => (
                          <tr key={index}>
                            <td className="text-sm md:text-lg border-2 border-blue-500 text-left pl-2 pr-5">{recordPayment['description']}</td>
                            <td className="text-sm md:text-lg border-2 border-blue-500 text-right pl-2 pr-2">Rp {toIDR(recordPayment['price'])}</td>
                            <td className="text-sm md:text-lg border-2 border-blue-500 text-left pl-2 pr-2">{recordPayment['time']}</td>
                            <td className="text-sm md:text-lg border-2 border-blue-500 text-left pl-2 pr-2">{recordPayment['status']}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )
                }
                
                <p className="mt-3 font-semibold">Metode Pembayaran</p>
                <table className="table-auto">
                    <thead className="border-2 border-gray-800">
                      <tr>
                        <th className="text-sm md:text-lg border-2 border-gray-800 bg-gray-800 text-gray-100 text-center">Nama Bank</th>
                        <th className="text-sm md:text-lg border-2 border-gray-800 bg-gray-800 text-gray-100 text-center">No. Rekening</th>
                        <th className="text-sm md:text-lg border-2 border-gray-800 bg-gray-800 text-gray-100 text-center">Nama Rekening</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-sm md:text-lg border-2 border-gray-800 text-center pl-5 pr-5">Bank BCA</td>
                        <td className="text-sm md:text-lg border-2 border-gray-800 text-center pl-5 pr-5">628-182-4055</td>
                        <td className="text-sm md:text-lg border-2 border-gray-800 text-center pl-5 pr-5">PT Jelajah Arsa Gemilang</td>
                      </tr>
                    </tbody>
                </table>
                { showTaxInformation ? 
                  <p className="text-sm">DPP Objek PPh 23 (2%) hanya dari Paket Tour. Untuk pemotongan PPh 23 harap disertai dengan bukti potong PPh 23 dan dapat dikirimkan ke email <span className="font-semibold">jelajah.arsa@gmail.com</span>. Pemotongan pembayaran tanpa bukti potong dianggap sebagai pembayaran yang belum lunas.</p> : null}

                <p className="mt-3 font-semibold">Mekanisme Pembatalan</p>
                <table className="table-auto">
                    <thead className="border-2 border-gray-800">
                      <tr>
                        <th className="text-sm md:text-lg border-2 border-gray-800 pl-5 pr-5 bg-gray-800 text-gray-100 text-center">Sebelum Tour</th>
                        <th className="text-sm md:text-lg border-2 border-gray-800 pl-5 pr-5 bg-gray-800 text-gray-100 text-center">Down Payment</th>
                        <th className="text-sm md:text-lg border-2 border-gray-800 pl-5 pr-5 bg-gray-800 text-gray-100 text-center">Full Payment</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        refundOptions.length === 0 ? (
                          <tr>
                            <td className="text-sm md:text-lg border-2 border-gray-800 text-center">-</td>
                            <td className="text-sm md:text-lg border-2 border-gray-800 text-center">-</td>
                            <td className="text-sm md:text-lg border-2 border-gray-800 text-center">-</td>
                          </tr>
                        ) : null
                      }
                      {
                        refundOptions.map((refundOption, index) => 
                        <tr key={index}>
                          <td className="text-sm md:text-lg border-2 border-gray-800 text-center">{refundOption.timeframe}</td>
                          <td className="text-sm md:text-lg border-2 border-gray-800 text-center">{refundOption.downpayment}</td>
                          <td className="text-sm md:text-lg border-2 border-gray-800 text-center">{refundOption.fullpayment}</td>
                        </tr>
                        )
                      }
                    </tbody>
                </table>
                
                <p className="mt-3">Salam hangat</p> 
                <p className="mt-10 font-semibold underline">Jelajah Holiday Tour & Travel</p>
                <p>PT Jelajah Arsa Gemilang</p>
              </div>
            </div>
          </div>
          <div className="ml-10 mt-5 mr-10">
            <div className="border border-gray-300 rounded-md p-3 mt-5 shadow-lg">
              <Input label={'Customer'} placeholder={'Customer Name'} onChange={(e) => setCustomer(e.target.value)} value={customer} changingValue/>
              <div className="relative w-full">
                <label className="block font-semibold mt-2"/>Trip Name :
                <select className="px-3 py-2.5 mt-2 w-full overflow-clip border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        value={tripName} 
                        onChange={handleTripNameChange}>
                  <option value="">Select Here</option>
                {
                    tourList.map(tourDetail => {
                        return(
                            <option key={tourDetail.id} value={tourDetail.data.title}>{tourDetail.data.title}</option>
                        )
                    })
                }
                </select>
              </div>         
              <div className="flex space-x-2">
                <div className="w-3/4">
                  <Input label={'Trip Date'} placeholder={'Trip Date'} onChange={(e) => setTripDate(e.target.value)} value={tripDate} changingValue/>
                </div>
                <div className="w-1/4">
                  <Input label='Trip Pax' placeholder={'Trip Pax'} onChange={(e) => setTripPax(e.target.value)} value={tripPax} changingValue/>
                </div>
              </div>
            </div>
            <div className="border border-gray-300 rounded-md p-3 mt-5 shadow-lg">
              {Array.from({ length: priceDetailCounter }).map((_, index) => (
                <div key={index} className="relative border mb-2 p-2 bg-gray-100 rounded-md shadow-md">
                  <div className="absolute right-1 top-1 cursor-pointer bg-gray-500 hover:bg-gray-300 px-2 rounded-md font-semibold text-gray-100 hover:text-gray-600" onClick={() => {handleRemovePriceDetail(index)}}>X</div>
                  <Input id='description' label='Deskripsi' placeholder='Deskripsi' onChange={(e) => handlePriceChange(index, e.target.id, e.target.value)} value={priceDetails[index] ? priceDetails[index]['description'] : ''} changingValue/>
                  <div className="flex space-x-2">
                    <div className="w-3/4">
                      <Input id='price' label='Harga' placeholder='Harga' onChange={(e) => handlePriceChange(index, e.target.id, e.target.value)} value={priceDetails[index] ? toIDR(priceDetails[index]['price']) : ''} changingValue/>
                    </div>
                    <div className="w-1/4">
                      <Input id='quantity' label='Jumlah' placeholder='Jumlah' onChange={(e) => handlePriceChange(index, e.target.id, e.target.value)} value={priceDetails[index] ?priceDetails[index]['quantity'] : ''} changingValue/>
                    </div>
                  </div>
                </div>
              ))}
              <div className="mt-5">
                <Button title='+ Price Detail' onClick={() => setPriceDetailCounter(priceDetailCounter + 1)}/>
              </div>

              {/* Keterangan PPh */}
              <div>
                <Input 
                  id='showTaxInformation'
                  type='checkbox'
                  label='Tambah keterangan pph 23'
                  checked={showTaxInformation}
                  onChange={() => setShowTaxInformation(!showTaxInformation)}/>
              </div>
            </div>

            <div className="border border-gray-300 rounded-md p-3 mt-5 shadow-lg">
              {Array.from({ length: recordPaymentCounter }).map((_, index) => (
                <div key={index} className="relative border mb-2 p-2 bg-gray-100 rounded-md shadow-md">
                  <div className="absolute right-1 top-1 cursor-pointer bg-gray-500 hover:bg-gray-300 px-2 rounded-md font-semibold text-gray-100 hover:text-gray-600" onClick={() => {handleRemovePaymentRecord(index)}}>X</div>
                  <Input id='description' label='Deskripsi' placeholder='Deskripsi' onChange={(e) => handleRecordPaymentChange(index, e.target.id, e.target.value)} value={recordPayments[index] ? recordPayments[index]['description'] : ''} changingValue/>
                  <div className="flex space-x-2">
                    <div className="w-2/4">
                      <Input id='time' label='Waktu' placeholder='Waktu' onChange={(e) => handleRecordPaymentChange(index, e.target.id, e.target.value)} value={recordPayments[index] ? recordPayments[index]['time'] : ''} changingValue/>
                    </div>
                    <div className="w-2/4">
                      <Input id='price' label='Harga' placeholder='Harga' onChange={(e) => handleRecordPaymentChange(index, e.target.id, e.target.value)} value={recordPayments[index] ? toIDR(recordPayments[index]['price']) : ''} changingValue/>
                    </div>
                  </div>
                  <div className="relative w-full">
                      <label className="block font-semibold mt-2"/>Status Pembayaran :
                      <select className="px-3 py-2.5 mt-2 w-full overflow-clip border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              id='status'
                              value={recordPayments[index] ? recordPayments[index]['status'] : ''}
                              onChange={(e) => handleRecordPaymentChange(index, e.target.id, e.target.value)}>
                        <option value="">Select Here</option>
                        <option value="MENUNGGU PEMBAYARAN">MENUNGGU PEMBAYARAN</option>
                        <option value="LUNAS">LUNAS</option>
                        <option value="DIBATALKAN">DIBATALKAN</option>
                      </select>
                    </div>
                </div>
              ))}
              {
                paymentStatus === 'LUNAS' ? '' : (
                  <div className="border border-gray-300 rounded-md p-3 mt-5 shadow-lg">
                    <label className="block">Set Payment Reminder:</label>
                    <CustomDatepicker limitDate={false} onPick={setReminderPaymentDate} initialSelectedDate={reminderPaymentDate}/>
                    <div className="w-full">
                      <Input id='receiver' label='Email Receivers' placeholder='customer@gmail.com,customer2@gmail.com,. . .' onChange={(e) => {setReminderReceiverEmails(e.target.value)}} value={reminderReceiverEmails} changingValue/>
                    </div>
                  </div>
                )
              }
              <div className="mt-5">
                <Button title='+ Record Payment' onClick={() => setRecordPaymentCounter(recordPaymentCounter + 1)}/>
              </div>
            </div>

            {
              loadingDownload ? (
                <div className="mt-10">
                  <div className="w-full h-0.5px bg-gray-900 mb-3"></div>
                  <p>Loading...</p>
                </div>
              ) : (
                <div>
                  <div className="mt-10">
                    <div className="w-full h-0.5px bg-gray-900 mb-3"></div>
                      <Button title='Save & Export PDF' onClick={async () => {
                        setLoadingDownload(true)
                        await handleSaveInvoice()
                        if(paymentStatus !== 'LUNAS'){
                          await handleSetPaymentReminder()
                        }else{
                          // TODO CANCEL
                          await handleCancelPaymentReminder()
                        }
                        await handleDownloadPDF()
                        setLoadingDownload(false)
                      }}/>
                  </div>

                  <div className="mt-5">
                    <div className="px-5 py-3 bg-yellow-600 hover:bg-yellow-500 inline text-white rounded-lg cursor-pointer" onClick={async () => {
                      setLoadingDownload(true)
                      await handleSaveInvoice()
                      if(paymentStatus !== 'LUNAS'){
                        await handleSetPaymentReminder()
                      }else{
                        // TODO CANCEL
                        await handleCancelPaymentReminder()
                      }
                      setLoadingDownload(false)
                    }}>
                      Save Only
                    </div>
                  </div>
                </div>
              )
            }
            
          </div>
        </div>
      </div>
    </ExclusiveLayout>
  )
}

export default ExclusiveEditInvoice
