import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { sumIDR, toIDR } from "../../../../../utils/toIDR"
import ExclusiveLayout from "../../../../ExclusiveLayout"
import { retrieveVouchers } from "../../../../../config/redux/action/voucher"

const ExclusiveManageVoucher = () => {
  const [voucherList, setVoucherList] = useState([])
  
  const retrieveVoucherList = async () => {
    const data = await retrieveVouchers()
    console.log('DATA', data)
    setVoucherList(data)
  }

  useEffect(() => {
    retrieveVoucherList()
  },[])
  
  return(
    <ExclusiveLayout>
      <div className="w-full min-h-screen">
        <div className="my-10 ml-5 md:ml-20">
          <Link to={"/exclusive/voucher/new"}>
            <div className="px-2 py-2 bg-yellow-600 hover:bg-yellow-500 inline text-white rounded-sm cursor-pointer">
              + Create New Voucher
            </div>
          </Link>
        </div>
        <div className="mt-10 ml-5 md:ml-20 mb-10 overflow-scroll">
          <table className="table-auto">
            <thead className="border-2 border-gray-800">
              <tr>
                <th className="text-sm md:text-md border-2 border-blue-500 bg-blue-500 text-gray-100 text-center">Voucher Code</th>
                <th className="text-sm md:text-md border-2 border-blue-500 bg-blue-500 text-gray-100 text-center">Customer</th>
                <th className="text-sm md:text-md border-2 border-blue-500 bg-blue-500 text-gray-100 text-center">Domestic Discount</th>
                <th className="text-sm md:text-md border-2 border-blue-500 bg-blue-500 text-gray-100 text-center">International Discount</th>
                <th className="text-sm md:text-md border-2 border-blue-500 bg-blue-500 text-gray-100 text-center">Expired At</th>
              </tr>
            </thead>
            <tbody>
              {voucherList.map((voucher, index) => (
                <tr key={index}>
                  <td className="text-sm md:text-md border-2 border-blue-500 px-5 text-blue-500 hover:text-blue-400 cursor-pointer" onClick={() => {
                    window.location.href=(`/exclusive/voucher/edit/${voucher.id}` )
                  }} >{voucher.voucherCode}</td>
                  <td className="text-sm md:text-md border-2 border-blue-500 pl-2 pr-2">{voucher.customer}</td>
                  <td className="text-sm md:text-md border-2 border-blue-500 pl-2 pr-2">Rp {toIDR(voucher.domesticDiscount)}</td>
                  <td className="text-sm md:text-md border-2 border-blue-500 pl-2 pr-2">Rp {toIDR(voucher.intlDiscount)}</td>
                  <td className="text-sm md:text-md border-2 border-blue-500 pl-2 pr-2">{voucher.expiryDate}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </ExclusiveLayout>
  )
}

export default ExclusiveManageVoucher