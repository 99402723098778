import React, { Component } from 'react';
import { connect } from 'react-redux'
import Input from '../../../../components/atoms/Input';
import Button from '../../../../components/atoms/Button';
import { loginUserAPI, registerUserAPI } from '../../../../config/redux/action';


class ExclusiveLogin extends Component {
  state = {
    loginMode: true,
    email: '',
    password: '',
    wrongPassword: '',
  }

  handleToggleRegisterLogin = () => {
    this.setState({
      loginMode: !(this.state.loginMode)
    })
  }

  handleChangeText = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleLoginSubmit = async () => {
    const {history} = this.props
    const data = {
      email: this.state.email,
      password: this.state.password
    }
    const res = await this.props.loginAPI(data).catch(err => err)
    if (res) {
      localStorage.setItem('userData', JSON.stringify(res))
      this.setState({
        email: '',
        password: ''
      })
      history.push('/')
    } else {
      this.setState({
        wrongPassword: true
      })
    }
  }

  handleRegisterSubmit = async () => {
    const { history } = this.props
    const data = {
      email: this.state.email,
      password: this.state.password
    }
    const res = await this.props.registerAPI(data).catch(err => err)
    if (res) {
      this.setState({
        email: '',
        password: ''
      })
      history.push('/')
    }
  }

  validateLogin = () => {

  }

  render() {
    const {
      loginMode,
      wrongPassword
     } = this.state;
    return(
      <div className='min-h-screen'>
        <div className="my-4">
          <div className="flex justify-center text-center sm:block sm:p-0">
          <span className="hidden sm:inline-block sm:align-middle" aria-hidden="true">&#8203;</span>
          {
            (loginMode) ? (
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="h-14 pt-3 bg-blue-500 rounded-t-md text-center">
                  <span className="text-2xl font-light text-gray-100">Log in to your account</span>
                </div>
                <div className="relative py-3 md:w-96 sm:max-w-xl mx-auto text-center">
                  <div className="py-6 px-8 text-left">
                    <Input id="email" label="Email" onChange={this.handleChangeText} type="text" placeholder="Email" value={this.props.email}/>
                    <Input id="password" label="Password" onChange={this.handleChangeText} type="password" placeholder="Password" value={this.props.password}/>
                    {
                      wrongPassword ? (
                        <p className="text-red-600">Wrong Email/Password</p>
                      ) : (
                        null
                      )
                    }
                    <div className="flex justify-between items-baseline mt-5">
                      <Button onClick={this.handleLoginSubmit} title="Log in"/>
                    </div>
                  </div>
                </div>
              </div>
            ) 
            : null
          }
          </div>
        </div>
      </div>
    )
  }
}

const reduxState = (state) => ({
})

const reduxDispatch = (dispatch) => ({
  loginAPI: (data) => dispatch(loginUserAPI(data)),
  registerAPI: (data) => dispatch(registerUserAPI(data))
})

export default connect(reduxState, reduxDispatch)(ExclusiveLogin);